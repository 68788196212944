import React from 'react'

function HomePage() {
	return (
		<div>
			<p>home page</p>
		</div>
	)
}

export default HomePage
